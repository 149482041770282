import React from "react";
import "./reminder.scss";
function PaymentReminder({ closeModel }: any) {
  return (
    <div className="payment_reminder">
      <div className="container">
        <h2>Payment Reminder </h2>
        <p> Dear User, </p>
        <p>
          We hope you're enjoying our software! To continue using all the
          features and benefits, please kindly proceed with your payment at your
          earliest convenience.
        </p>
        <p> Thank you for your prompt attention to this matter.</p>
        <p> Best regards, </p>
        <p> Care32 Dent Software</p>
        <button onClick={() => closeModel()}>Click OK</button>
      </div>
    </div>
  );
}

export default PaymentReminder;
