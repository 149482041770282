import React, { ReactNode } from 'react'
import { CloseSVG } from '../../assets/icons';
import "./commonmodal.scss"
function CommonModal({
    children,
    closeModal,
    title
  }: {
    children: ReactNode;
    closeModal: any;
    title?: any;
  }) {
    return (
      <div className="commonmodal">
        <div className="container">
          <header>
            <div className="title">{title}</div>
            <CloseSVG onClick={() => closeModal()} />
          </header>
          <div className="contant">{children}</div>
        </div>
      </div>
    );
  }
  
export default CommonModal