const initialState = {
  activateTime: false,
};

export const managetimeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_TD_ACTIVE":
      const {activateTime} = action.payload
      return {
        ...state,
        activateTime: activateTime
      };

    default:
      return state;
  }
};
