import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { ArrowRight, SelectArrowDown } from "../../assets/icons";

function NavItem({ item, handleClose }: any) {
  const [subNavOpen, setsubNavOpen] = useState(false);
  const subNavRef = useRef<HTMLUListElement>(null);
  const toggleSubNav = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    setsubNavOpen((prev) => !prev);
    event.preventDefault(); // Prevent default behavior to control toggling
  };

  const getSubNavHeight = () => {
    if (subNavRef.current) {
      return subNavOpen ? `${subNavRef.current.scrollHeight}px` : "0px";
    }
    return "0px";
  };
  if (item?.subnav?.length > 0) {
    return (
      <li>
        <NavLink
          to={item?.path}
          className={({ isActive }) =>
            isActive ? "nav-list nav-list-active-text" : "nav-list"
          }
          onClick={(event) => {
            setsubNavOpen(!subNavOpen);
            event.preventDefault();
          }}
        >
          <div className="nav-list-item">
            <div className="list-icon">
              {item?.icon && <item.icon className="list-icons" />}
            </div>
            {item?.title}
          </div>
          {item?.subnav?.length > 0 &&
            (subNavOpen ? <SelectArrowDown key={1} /> : <ArrowRight key={2} />)}
        </NavLink>
        <ul
          ref={subNavRef}
          style={{
            height: getSubNavHeight(),
          
          }}
        >
          {/* <ul> */}
          {item?.subnav?.map(
            (items: any, j: number) =>
              !items?.hide && (
                <li key={j}>
                  <NavLink
                    onClick={() => handleClose()}
                    to={items?.path}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-list-child nav-list-child-active"
                        : "nav-list-child "
                    }
                  >
                    <div className="nav-list-item">{items?.title}</div>
                  </NavLink>
                </li>
              )
          )}
        </ul>
      </li>
    );
  } else {
    return (
      <li>
        <NavLink
          onClick={() => handleClose()}
          to={item?.path}
          className={({ isActive }) =>
            isActive ? "nav-list nav-list-active" : "nav-list"
          }
        >
          <div className="nav-list-item">
            <div className="list-icon">
              {item?.icon && <item.icon className="list-icons" />}
            </div>
            {item?.title}
          </div>
        </NavLink>
      </li>
    );
  }
}

export default NavItem;
