export function daysLeftFromToday(targetDate: any) {
  const today = new Date();
  const target = new Date(targetDate);
  const differenceInTime = today.getTime() - target.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;
}
export const requestTime = (targetDate: any) => {
  const count = daysLeftFromToday(targetDate);
  // 30
  if (count <= 0) {
    return 0;
  } else if (5 <= count && count < 10) {
    return 240;
  } else if (10 <= count && count < 15) {
    return 120;
  } else if (15 <= count && count < 20) {
    return 0.5;
  } else if (20 < count) {
    return 1 / 60;
  } else {
    return 0;
  }
};
export const requestAlert = (targetDate: any) => {
  const count = daysLeftFromToday(targetDate);
  // 30

  if (count <= 0) {
    return "no alert";
  } else if (0 <= count && count < 10) {
    return "4 hours";
  } else if (10 <= count && count < 15) {
    return "3 hours";
  } else if (15 <= count && count < 20) {
    return "30 sec";
  }else if (20 < count) {
    return "1 sec";
  }
};
