interface AccessAction {
  [key: string]: {
    PATH: string;
    KEY: number;
    NAME: string;
  };
}
export const ACCESSROUTES: AccessAction = {
  DASHBOARD: {
    PATH: "/dashboard",
    KEY: 1,
    NAME: "dashboard",
  },
  PATIENTS: {
    PATH: "/patients",
    KEY: 2,
    NAME: "patients",
  },
  ADDPATIENTS: {
    PATH: "/patients/add-patients",
    KEY: 21,
    NAME: "add patients",
  },
  ADDPATIENTSQUICKADD: {
    PATH: "/patients/add-patients/quick-add",
    KEY: 211,
    NAME: "add patients",
  },
  ADDPATIENTSFULLREGISTRATION: {
    PATH: "/patients/add-patients/full-registration",
    KEY: 212,
    NAME: "add patients",
  },
  VIEWPATIENTS: {
    PATH: "/patients/view-patients",
    KEY: 22,
    NAME: "add patients",
  },
  VIEWPATIENTSACIVE: {
    PATH: "/patients/view-patients/active",
    KEY: 221,
    NAME: "add patients",
  },
  VIEWPATIENTSDEACIVE: {
    PATH: "/patients/view-patients/deactive",
    KEY: 222,
    NAME: "add patients",
  },
  EDITPATIENTS: {
    PATH: "/patients/edit-patients/:ID",
    KEY: 23,
    NAME: "add patients",
  },
  PATIENTSDETAIL: {
    PATH: "/patients/patientone",
    KEY: 24,
    NAME: "add patients",
  },
  PATIENTSGENERALDETAIL: {
    PATH: "/patients/patientone/general-detail/:ID",
    KEY: 241,
    NAME: "add patients",
  },
  PATIENTSEXAMINATIONDETAIL: {
    PATH: "/patients/patientone/examination/:ID",
    KEY: 242,
    NAME: "add patients",
  },
  PATIENTSMEDICALHISTORYDETAIL: {
    PATH: "/patients/patientone/medical-history/:ID",
    KEY: 243,
    NAME: "add patients",
  },
  PATIENTSAPPOINTMENTSDETAIL: {
    PATH: "/patients/patientone/appointments/:ID",
    KEY: 244,
    NAME: "add patients",
  },
  PATIENTSINVOCEDETAIL: {
    PATH: "/patients/patientone/invoices/:ID",
    KEY: 245,
    NAME: "add patients",
  },
  // ssd
  EXAMINATIONANDTREATMENTS: {
    PATH: "/treatment-diagnose",
    KEY: 3,
    NAME: "add patients",
  },
  VIEWTREATMENTS: {
    PATH: "/treatment-diagnose/all-treamentlist",
    KEY: 33,
    NAME: "add patients",
  },
  
  ADDTREATMENTDIAGNOSIS: {
    PATH: "/treatment-diagnose/add-treatment",
    KEY: 34,
    NAME: "add patients",
  },
  
  ADDTREATMENTDIAGNOSISADDDIAGNOSE: {
    PATH: "/treatment-diagnose/add-treatment/diagnose/:ID",
    KEY: 341,
    NAME: "add patients",
  },
  ADDTREATMENTDIAGNOSISTREATMENTTABLE: {
    PATH: "/treatment-diagnose/add-treatment/treatment-table/:ID",
    KEY: 342,
    NAME: "add patients",
  },
  ADDTREATMENTDIAGNOSISPASTTREATMENT: {
    PATH: "/treatment-diagnose/add-treatment/past-treatment/:ID",
    KEY: 343,
    NAME: "add patients",
  },
  PAYMENTS: {
    PATH: "/payments",
    KEY: 4,
    NAME: "add patients",
  },
  CREATINVOICE: {
    PATH: "/payments/invoice",
    KEY: 41,
    NAME: "add patients",
  },

  PAYMENTLIST: {
    PATH: "/payments/paymentlist",
    KEY: 42,
    NAME: "add patients",
  },
  ALLLASTPAYMENT: {
    PATH: "/payments/paymentlist/allpayments",
    KEY: 424,
    NAME: "add patients",
  },
  SHOULDBEPAY: {
    PATH: "/payments/paymentlist/shouldbepaid",
    KEY: 421,
    NAME: "add patients",
  },
  ADVANCED: {
    PATH: "/payments/paymentlist/advanced",
    KEY: 422,
    NAME: "add patients",
  },
  COMPLETEDPAYMENT: {
    PATH: "/payments/paymentlist/completed",
    KEY: 423,
    NAME: "add patients",
  },
  GOTOPAY: {
    PATH: "/payment/invoice/:ID",
    KEY: 43,
    NAME: "add patients",
  },
  ALLPAYMENTLIST: {
    PATH: "/payments/payment-list",
    KEY: 42,
    NAME: "payment",
  },
  STAFF: {
    PATH: "/staff",
    KEY: 5,
    NAME: "add patients",
  },
  ROLE: {
    PATH: "/staff/role",
    KEY: 51,
    NAME: "add patients",
  },
  ALLROLE: {
    PATH: "/staff/role/role",
    KEY: 511,
    NAME: "add patients",
  },
  VIEWROLE: {
    PATH: "/staff/role/view-role",
    KEY: 512,
    NAME: "add patients",
  },
  ADDROLE: {
    PATH: "/staff/role/add-role",
    KEY: 513,
    NAME: "add patients",
  },
  DEACTIVEROLE: {
    PATH: "/staff/role/deactive-role",
    KEY: 514,
    NAME: "add patients",
  },
  EDITROLE: {
    PATH: "/staff/role/edit-role/:ID",
    KEY: 515,
    NAME: "add patients",
  },
  EMPLOYEE: {
    PATH: "/staff/employee",
    KEY: 52,
    NAME: "add patients",
  },
  ALLEMPLOYEES: {
    PATH: "/staff/employee/employees",
    KEY: 521,
    NAME: "add patients",
  },
  DEACTIVEEMPLOYEES: {
    PATH: "/staff/employee/deactive-employees",
    KEY: 522,
    NAME: "add patients",
  },
  ADDEMPLOYEE: {
    PATH: "/staff/employee/add-employee",
    KEY: 523,
    NAME: "add patients",
  },
  EDITEMPLOYEE: {
    PATH: "/staff/employee/edit-employee/:ID",
    KEY: 523,
    NAME: "edit patients",
  },
  APPOINTMENT: {
    PATH: "/appointment",
    KEY: 6,
    NAME: "appointment",
  },
  MAKEAPPOINTMENT: {
    PATH: "/appointment/make-appointment",
    KEY: 61,
    NAME: "make appointment",
  },
  VIEWAPPOINTMENT: {
    PATH: "/appointment/view-appointment",
    KEY: 62,
    NAME: "view appointment",
  },
  MYAPPOINTMENT: {
    PATH: "/appointment/view-appointment/my-appointment",
    KEY: 621,
    NAME: "view appointment",
  },
  ALLAPPOINTMENT: {
    PATH: "/appointment/view-appointment/all-appointment",
    KEY: 622,
    NAME: "view appointment",
  },
  SETTING: {
    PATH: "/setting",
    KEY: 7,
    NAME: "Setting",
  },
  SETTINGMANGETIME: {
    PATH: "/setting/managetime",
    KEY: 71,
    NAME: "Mange time",
  },
  SETTINGMANGEPRINT: {
    PATH: "/setting/manage-print",
    KEY: 72,
    NAME: "Mange print",
  },
  SETTINGMANGEPURPOSE: {
    PATH: "/setting/manage-purpose",
    KEY: 73,
    NAME: "Mange Purpose",
  },
  TREATMENTANDPRICELIST: {
    PATH: "/setting/treatment",
    KEY: 31,
    NAME: "add patients",
  },
  TREATMENTLIST: {
    PATH: "/setting/treatment/all-treatment",
    KEY: 311,
    NAME: "add patients",
  },
  ADDTREATMENTS: {
    PATH: "/setting/treatment/add-treatment",
    KEY: 312,
    NAME: "add patients",
  },
  DEACTIVETREATMENTLIST: {
    PATH: "/setting/treatment/deactive-treatment",
    KEY: 313,
    NAME: "add patients",
  },
  DIAGNOSIS: {
    PATH: "/setting/diagnose",
    KEY: 32,
    NAME: "add patients",
  },
  DIAGNOSELIST: {
    PATH: "/setting/diagnose/all-diagnose",
    KEY: 321,
    NAME: "add patients",
  },
  ADDDIAGNOSIS: {
    PATH: "/setting/diagnose/add-diagnose",
    KEY: 322,
    NAME: "add patients",
  },

  INVENTORY: {
    PATH: "/inventory",
    KEY: 8,
    NAME: "inventory",
  },
  SCALE: {
    PATH: "/inventory/scale",
    KEY: 81,
    NAME: "scale",
  },
  ALLSCALE: {
    PATH: "/inventory/scale/all-scale",
    KEY: 811,
    NAME: "scale",
  },
  CATEGORY: {
    PATH: "/inventory/category",
    KEY: 82,
    NAME: "category",
  },
  ALLCATEGORY: {
    PATH: "/inventory/category/all-category",
    KEY: 821,
    NAME: "category",
  },
  PRODUCT: {
    PATH: "/inventory/product",
    KEY: 83,
    NAME: "product",
  },
  ALLPRODUCT: {
    PATH: "/inventory/product/all-product",
    KEY: 831,
    NAME: "product",
  },
  BRAND: {
    PATH: "/inventory/brand",
    KEY: 84,
    NAME: "brand",
  },
  ALLBRAND: {
    PATH: "/inventory/brand/all-brand",
    KEY: 841,
    NAME: "brand",
  },
  SUPPLIER: {
    PATH: "/inventory/supplier",
    KEY: 85,
    NAME: "supplier",
  },
  ALLSUPPLIER: {
    PATH: "/inventory/supplier/all-supplier",
    KEY: 851,
    NAME: "brand",
  },
  PRODUCTRELEASE: {
    PATH: "/inventory/release",
    KEY: 86,
    NAME: "release",
  },
  ALLPRODUCTRELEASE: {
    PATH: "/inventory/release/all-release",
    KEY: 861,
    NAME: "release",
  },
  ADDPRODUCTRELEASE: {
    PATH: "/inventory/release/add-release",
    KEY: 862,
    NAME: "release",
  },
  PURCHASE: {
    PATH: "/inventory/purchase",
    KEY: 87,
    NAME: "purchase",
  },
  ALLPURCHASE: {
    PATH: "/inventory/purchase/all-purchase",
    KEY: 871,
    NAME: "purchase",
  },
  ADDPURCHASE: {
    PATH: "/inventory/purchase/add-purchase",
    KEY: 872,
    NAME: "purchase",
  },
  EDITPURCHASE: {
    PATH: "/inventory/purchase/edit-purchase/:ID",
    KEY: 873,
    NAME: "purchase",
  },
  MYACCOUNT: {
    PATH: "/my-account",
    KEY: 9,
    NAME: "myaccount",
  },
  MYAPPOINTMENTS: {
    PATH: "/my-account/my-appointment",
    KEY: 91,
    NAME: "my appointment",
  },

  MYAPPOINTMENTSADDTOTREATMENT: {
    PATH: "/treatment-diagnose/add-treatment",
    KEY: 92,
    NAME: "add patients",
  },
  MYAPPOINTMENTSADDTOTREATMENTDIAGNOSE: {
    PATH: "/treatment-diagnose/add-treatment/diagnose/:ID",
    KEY: 921,
    NAME: "add patients",
  },
  MYAPPOINTMENTSADDTOTREATMENTTREATMENT: {
    PATH: "/treatment-diagnose/add-treatment/treatment-table/:ID",
    KEY: 922,
    NAME: "add patients",
  },
  MYAPPOINTMENTSADDTOTREATMENTPASTTREATMENT: {
    PATH: "/treatment-diagnose/add-treatment/past-treatment/:ID",
    KEY: 923,
    NAME: "add patients",
  },
};
