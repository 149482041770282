const initialState = {
  doctors: [],
  empty:true
};

export const doctorReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_DOCTOR":
        
      return {
        ...state,
        doctors: action.payload.data,
        empty: action.payload.empty,
      };
   
    default:
      return state;
  }
};
