import React, { useCallback } from "react";
import CommonModal from "../../../../../components/modal/CommonModal";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../../../components/formFeild/FormInput";
import FormDate from "../../../../../components/formFeild/FormDate";
import FormCheck from "../../../../../components/formFeild/FormCheck";
import baseUrl from "../../../../../api/baseUrl";
interface AddCategoryProps {
  closeModal: () => void;
}
interface FormData {
  categoryName: string;
  startDate: string;
  endDate: string;
}
const AllPaymentReport: React.FC<AddCategoryProps> = ({ closeModal }) => {
  const methods = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = useCallback(
    async (data) => {
      //   closeModal();
      try {
        const response = await baseUrl.get("client/payment/paymentReport", {
          responseType: "blob",
          params: {
            startDate:data.startDate,
            endDate:data.endDate,
          },
        });

        const pdfBlob = new Blob([response.data], { type: "application/pdf" });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob);
        link.download = "downloaded.pdf"; 
        document.body.appendChild(link);

        link.click();

        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the PDF", error);
      }
    },
    [closeModal]
  );
  return (
    <CommonModal closeModal={closeModal} title="Payment report">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid">
            <FormDate label="from" name="startDate" />
            <FormDate label="to" name="endDate" />
          </div>
          <FormCheck label="patient Details" name="categoryNamAe " />
          <button type="submit" className="generate_button">
            Download As pdf
          </button>
        </form>
      </FormProvider>
    </CommonModal>
  );
};

export default AllPaymentReport;
