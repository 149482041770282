export const setPatients = (data: any) => ({
  type: "SET_PATIENTS",
  payload: data,
});
export const setNewPatients = (newData: any) => ({
  type: "SET_NEW_PATIENTS",
  payload: newData,
});
export const setPatientsCount = (count: number) => ({
  type: "SET_PATIENTS_COUNT",
  payload: count,
});
export const setNewPatientsCount = (newcount: number) => ({
  type: "SET_NEW_PATIENTS_COUNT",
  payload: newcount,
});
export const setDeactivePatients = (data: any) => ({
  type: "SET_DEACTIVE_PATIENTS",
  payload: data,
});
export const setDeactiveNewPatients = (newData: any) => ({
  type: "SET_DEACTIVE_NEW_PATIENTS",
  payload: newData,
});
export const setDeactivePatientsCount = (count: number) => ({
  type: "SET_DEACTIVE_PATIENTS_COUNT",
  payload: count,
});
export const setDeactiveNewPatientsCount = (newcount: number) => ({
  type: "SET_DEACTIVE_NEW_PATIENTS_COUNT",
  payload: newcount,
});
