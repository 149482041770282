import React from "react";
import NavItem from "./NavItem";

function Sidebaritem({ filteredArray ,handleClose}: any) {
  return (
    <div className="side-navbar">
      <ul>
        {filteredArray?.map((item: any, i: number) => (
          <NavItem handleClose={handleClose}  item={item} key={i} />
        ))}
       
      </ul>
    </div>
  );
}

export default Sidebaritem;
