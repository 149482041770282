import moment from "moment-timezone";


const appointmentState = {
  myappointmentCount: {},
  allappointmentCount: {},
  myappointment: [],
  myappointmentIsEMpty: true,
  allappointment: [],
  allappointmentIsEMpty: true,
  purpose: [],
  purposeIsEMpty: true,
  interval: {to:moment("2022-10-10T18:00:00").toDate(),from:moment("2022-10-10T09:00:00").toDate()},
  intervalIsEMpty: true,
};

export const appointmentReducer = (state = appointmentState, action: any) => {
  switch (action.type) {
    case "SET_ALLAPPOINTMENTCOUNT":
      return {
        ...state,
        allappointmentCount: action.payload,
      };
 
    case "SET_MYAPPOINTMENTCOUNT":
      return {
        ...state,
        myappointmentCount: action.payload,
      };
    case "SET_ALLAPPOINTMENT":
      return {
        ...state,
        allappointment: action.payload.data,
        allappointmentIsEMpty: action.payload.empty,
      };
      case "SET_NEW_ALLAPPOINTMENT":
        return {
          ...state,
          allappointment: [action.payload, ...state.allappointment],
        };
      
    case "SET_MYAPPOINTMENT":
      return {
        ...state,
        myappointment: action.payload.data,
        myappointmentIsEMpty: action.payload.empty,
      };
      case "SET_NEW_MYAPPOINTMENT":
        return {
          ...state,
          myappointment: [action.payload, ...state.myappointment],
        };
    case "SET_PURPOSE":
      return {
        ...state,
        purpose: action.payload.data,
        purposeIsEMpty: action.payload.empty,
      };
    case "SET_NEW_PURPOSE":
      return {
        ...state,
        purpose: [action.payload, ...state.purpose],
        purposeIsEMpty: action.payload.empty,
      };
    case "SET_TIMEINTERVAL":
      return {
        ...state,
        interval: action.payload.data,
        intervalIsEMpty: action.payload.empty,
      };
    default:
      return state;
  }
};
