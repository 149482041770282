import React from 'react'
import "./customLayout.scss"
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
function CustomLayout() {
    return (
        <div className="custom_layout">
            <div className="nav_layout">

                <label htmlFor="openSidebarnew" className="humber_icon">
                    <MenuOpenIcon />
                    <input type="checkbox" id="openSidebarnew" />
                </label>

                <div className="topNav">
                    <header>
                        <div className="title">Restorative Specialist Dental Chambers</div>
                    </header>
                </div>
            </div>
            <div className="main">
                <div className="drawer" id='newDrawer'>
                    <ul className='main_nav'>
                        <li>
                            <div className="nav_item">
                                <div className="icon"><MenuOpenIcon />
                                </div>
                                <Link to={"/dashboard"}>DashBoard</Link>
                            </div>
                        </li>
                        <li>
                            <div className="nav_item">
                                <div className="icon"><MenuOpenIcon />
                                </div>
                                <Link to={"/dashboard"}>DashBoard</Link>
                                <div className="drop_icon"><ArrowDropDownIcon /></div>
                            </div>
                        </li>
                        <ul className='sub_nav'>
                            <li>
                                <div className="nav_item">
                                    <div className="icon"><MenuOpenIcon />
                                    </div>
                                    <Link to={"/dashboard"}>DashBoard</Link>
                                </div>
                            </li>
                            <li>
                                <div className="nav_item">
                                    <div className="icon"><MenuOpenIcon />
                                    </div>
                                    <Link to={"/dashboard"}>DashBoard</Link>
                                </div>
                            </li>
                            <li>
                                <div className="nav_item">
                                    <div className="icon"><MenuOpenIcon />
                                    </div>
                                    <Link to={"/dashboard"}>DashBoard</Link>
                                </div>
                            </li>
                        </ul>
                        <li>
                            <div className="nav_item">
                                <div className="icon"><MenuOpenIcon />
                                </div>
                                <Link to={"/dashboard"}>DashBoard</Link>
                            </div>

                        </li>
                    </ul>
                </div>
                <div className="newContainer">dfdf</div>
            </div>
        </div>
    )
}
export default CustomLayout