import { createStore, combineReducers } from 'redux';
import { diagnoseReducer } from './diagnosestate/diagnoseReducer';
import { searchReducer } from './searchState/searchReducer';
import { loaderReducer } from './loader/loaderReducer';
import { managetimeReducer } from './managetime/managetimeReducer';
import { printReducer } from './printstate/printReducer';
import { paymentReducer } from './paymentState/paymentReducer';
import { patientsReducer } from './patients/patientsReducer';
import { appointmentReducer } from './appointment/appointmentReducer';
import { doctorReducer } from './doctor/doctorReducer';
const rootReducer = combineReducers({
    diagnose:diagnoseReducer,
    searching:searchReducer,
    loader:loaderReducer,
    managetime:managetimeReducer,
    print:printReducer,
    payment:paymentReducer,
    patients:patientsReducer,
    appointment:appointmentReducer,
    doctor:doctorReducer,
});
const store = createStore(rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
export default store;