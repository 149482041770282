const initialState = {
    id: "",
    paymentOpen: false,
    
  };
  
  export const paymentReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "SET_PAYMENT_ID":
          
        const {id} = action.payload
            return {
                ...state,
                id: id,
            };
        case "SET_PAYMENT_OPENCLOSE":
          

            return {
                ...state,
                paymentOpen: !state.paymentOpen,
            };
      
      default:
        return state;
    }
  };
  