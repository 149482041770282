
interface AccessAction {
    [key: string]: {
      PATH: string;
      KEY: number;
      NAME: string;
      METHOD: string;
    };
  }
export const ACCESSACTION: AccessAction = {
    FINDALLPATIENT: {
      
      PATH: 'patient/findAll',
      KEY: 1,
      NAME: "dashboard",
      METHOD:"GET"
    },
    DELETEPATIENT: {
      PATH: 'patient/delete',
      KEY: 2,
      NAME: "dashboard",
      METHOD:"DELETE"
    },
}