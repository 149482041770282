
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
function FormDate({
  name,
  type,
  label,
  errors,
  errormessage,
  placeholder,
  defaultValue,
  ...others
}: any) {
  const { control } = useFormContext();
  return (
    <div className="form__group">
      <label className="form__label">{label ? label : "name"}</label>

      <Controller
        name={name ? name : ""}
        control={control}
        defaultValue={defaultValue ? defaultValue : ""}
        render={({ field: { onChange, value } }) => (
          <input
            onChange={onChange}
            value={value}
            type={"date"}
            className="form__field "
            placeholder={placeholder ? placeholder : ""}
            required
          />
        )}
      />
      {errors && (
        <label className="form__label__error">
          {errormessage ? errormessage : "error"}
        </label>
      )}
    </div>
  );
}

export default FormDate;
