import { io, Socket, connect } from "socket.io-client";
import CryptoJS from "crypto-js";

const decryptToken = (token: string, secret: string): string | undefined => {
    try {
      return CryptoJS.AES.decrypt(token, secret).toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Error decrypting token:", error);
      return undefined;
    }
  };
const secret = process.env.REACT_APP_SECRET || "sfdsfasdfsdf3434$$$";
const token: string | null = localStorage.getItem("token");
const decryptedToken = token ? decryptToken(token, secret) : undefined;

const socketauth = {
    auth: {
      token: decryptedToken,
    },
  };


export default socketauth;
