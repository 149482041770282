export const setAllAppointmentCount = (data: any) => ({
  type: "SET_ALLAPPOINTMENTCOUNT",
  payload: data,
});

export const setMyAppointmentCount = (data: any) => ({
  type: "SET_MYAPPOINTMENTCOUNT",
  payload: data,
});

export const setAllAppointment = (data: any, empty: boolean) => ({
  type: "SET_ALLAPPOINTMENT",
  payload: { data, empty },
});
export const setNewAllAppointment = (data: any) => ({
  type: "SET_NEW_ALLAPPOINTMENT",
  payload: data,
});
export const setMyAppointment = (data: any, empty: boolean) => ({
  type: "SET_MYAPPOINTMENT",
  payload: { data, empty },
});
export const setNewMyAppointment = (data: any) => ({
  type: "SET_NEW_MYAPPOINTMENT",
  payload: data,
});
export const setPurpose = (data: any, empty: boolean) => ({
  type: "SET_PURPOSE",
  payload: { data, empty },
});
export const setNewPurpose = (data: any) => ({
  type: "SET_NEW_PURPOSE",
  payload: data,
});
export const setTimeInterval = (data: any, empty: boolean) => ({
  type: "SET_TIMEINTERVAL",
  payload: { data, empty },
});
