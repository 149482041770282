import React, { Suspense, useCallback, useEffect, useState } from "react";
import "./layoutmain.scss";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import logo from "../../assets/icons/Care32-01.png";
import getDecryptedToken from "../../api/getDecryptedToken";
import { BellSvg, UserSvg } from "../../assets/icons";
import Sidebar from "../../components/sidebar/Sidebar";
import Sidebaritem from "../../components/sidebar/Sidebaritem";
import { Circles } from "react-loader-spinner";
import { Outlet } from "react-router-dom";
import axios from "axios";
import baseUrl from "../../api/baseUrl";
import { useDispatch } from "react-redux";
import { io, Socket, connect } from "socket.io-client";

import socketauth from "../../api/socket";
import { setNewPatients } from "../../redux/patients/patientsAction";
import {
  setNewAllAppointment,
  setNewMyAppointment,
  setNewPurpose,
} from "../../redux/appointment/appointmentAction";
import PaymentReminder from "../../client/components/reminder/PaymentReminder";
import { requestTime } from "../../utils/alerts/remainder";
const clinicName: string | undefined = getDecryptedToken("clinicName");
const userName: string | undefined = getDecryptedToken("userName");
const socket = io(`${process.env.REACT_APP_WS}`, socketauth);
const socketEmit: Socket = connect(`${process.env.REACT_APP_WS}`, socketauth);
function LayoutMain({ filteredArray }: any) {
  const [pay, setPay] = useState(false);
  const [open, setopen] = useState(window.innerWidth > 499);
  const dispatch = useDispatch();

  const [logout, setlogout] = useState(false);
  const handleSocketUpdate = useCallback(
    (event: any, action: Function) => {
      if (event.token.clinicId === getDecryptedToken("cid")) {
        event.data.new = true;
        dispatch(action(event.data));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    socket.on("updatePatients", (e) => handleSocketUpdate(e, setNewPatients));
    socket.on("uppdateAppointment", (e) =>
      handleSocketUpdate(e, setNewAllAppointment)
    );
    socket.on("uppdateMyAppointment", (e) =>
      handleSocketUpdate(e, setNewMyAppointment)
    );
    socket.on("updatepurpose", (e) => handleSocketUpdate(e, setNewPurpose));
    return () => {
      socket.off("updatePatients");
      socket.off("uppdateAppointment");
      socket.off("uppdateMyAppointment");
      socket.off("updatepurpose");
    };
  }, [handleSocketUpdate]);

  const getdata = useCallback(async () => {
    try {
      const response = await baseUrl.get(
        "client/payment/getCientClinicPaymentbyId"
      );

      if (response && response?.data?.success) {
        const reqtime = requestTime(response?.data?.data?.split("T")[0]);

        if (reqtime > 0) {
          const timeoutd = setTimeout(
            () => {
              setPay(true);
            },
            reqtime * 1000 * 60
          );
          return () => clearTimeout(timeoutd);
        }
      } else {
        setPay(false);
      }
    } catch (error: any) {
      console.error(error.response);
    }
  }, [baseUrl]);
  const closePaymentModel = useCallback(() => {
    setPay(false);
    getdata();
  }, []);

  useEffect(() => {
    getdata();
  }, [getdata]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.country.is");
        localStorage.setItem("country", response.data.country);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);
  const logOut = () => {
    localStorage.clear();
    window.location.reload();
    window.location.href = "/login";
  };

  const handleClose = useCallback(() => {
    if (!(window.innerWidth > 499)) {
      setopen(false);
    }
  }, []);
  return (
    <div className="layout_main">
      <div className={`layout_main_nav ${open ? "open_nav" : "close_nav"}`}>
        <div className={`title_head ${open ? "open_nav" : "close_nav"}`}>
          <img src={logo} alt="car32dentsoftware" />
        </div>
        <div className={"side_navigation"}>
          <div className="empty"></div>
          <Sidebaritem
            handleClose={handleClose}
            filteredArray={filteredArray}
          />
        </div>
      </div>
      {open && (
        <label onClick={() => setopen((e) => !e)} className="moileOpen"></label>
      )}

      <div className="layout_main_main">
        <header className={`${open ? "open_header" : "close_header"}`}>
          <div className="layout_main_header">
            <div className="left">
              <button className="menu_icon" onClick={() => setopen((e) => !e)}>
                <MenuOpenIcon />
              </button>
              <div className="title_clinic">{clinicName}</div>
            </div>
            <div className="right">
              <h3>{userName}</h3>
              <div className="notification">
                <BellSvg />
              </div>
              <div className="user_logout">
                <UserSvg onClick={() => setlogout(!logout)} />
                {logout && (
                  <ul>
                    <li onClick={() => logOut()}>Log Out</li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </header>
        <main className={`${open ? "open_header" : "close_header"}`}>
          <Suspense
            fallback={
              <Circles
                height="80"
                width="80"
                color="#174d7a"
                ariaLabel="bars-loading"
                wrapperStyle={{
                  position: "fixed",
                  inset: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                wrapperClass=""
              />
            }
          >
            <Outlet />
          </Suspense>
        </main>
      </div>
      {pay && <PaymentReminder closeModel={closePaymentModel} />}
    </div>
  );
}

export default LayoutMain;

