import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { RootState } from "../../../../redux/store";
import {
  setloadeActive,
  setloadeDeactive,
} from "../../../../redux/loader/loaderActions";
import baseUrl from "../../../../api/baseUrl";
import FormDate from "../../../../components/formFeild/FormDate";
import FormCheck from "../../../../components/formFeild/FormCheck";
import BottomTab from "../../../../components/pagination/BottomTab";
import AllPaymentReport from "../payments/allpayments/AllPaymentReport";
import CommonLayout from "../../../../layouts/commonLayout/CommonLayout";
import Container from "../../../../layouts/containers/Container";
interface FormData {
  payments: boolean;
  startDate: string;
  endDate: string;
}
function AllPaymentList() {
  const navigate = useNavigate();
  const [report, setreport] = useState(false);
  const [openpayments, setopenpayments] = useState(false);
  const [payment, setpayment] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const search = useSelector((state: RootState) => state.searching.patientData);
  const methods = useForm<FormData>();
  const { watch, handleSubmit, reset } = methods;
  const dispatch = useDispatch();
  const getData = useCallback(
    (
      searchTerm: string,
      pageNum: number,
      startDate?: string,
      endDate?: string
    ) => {
      dispatch(setloadeActive());
      baseUrl
        .get("payment/getAllPayment-list", {
          headers: {
            keyword: searchTerm
          },
          params: {
            startDate: startDate,
            endDate: endDate
          },
        })
        .then((response) => {
          dispatch(setloadeDeactive());
          
          setpayment(response.data.data);
          setCount(response.data.count);
        })
        .catch((error) => {
          dispatch(setloadeDeactive());
        });
    },
    [dispatch]
  );
  useEffect(() => {
    getData(search, page);
  }, [search, page, getData]);
  useEffect(() => {
    const { unsubscribe } = watch((value) => {
      if (value.startDate && value.endDate)
        getData("", page, value.startDate, value.endDate);
      if (value.payments) {
        setopenpayments(true);
      } else {
        setopenpayments(false);
      }
    });
    return () => unsubscribe();
  }, [watch]);
  const onPageChange = useCallback(
    (newPage: number) => {
      setPage(newPage);
      getData(search, newPage);
    },
    [getData, search]
  );
  const closeModal = useCallback(() => {
    setreport(false);
  }, []);
  const onSubmit: SubmitHandler<FormData> = useCallback(
    async (data) => {
      //   closeModal();

      try {
        const response = await baseUrl.get("client/payment/paymentReport", {
          responseType: "blob",
          params: {
            startDate: data.startDate,
            endDate: data.endDate,
            payments: data.payments,
          },
        });
        if (response.status === 204) {
          toast("No data found");
          return;
        }
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob);
        link.download = "downloaded.pdf";
        document.body.appendChild(link);

        link.click();

        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the PDF", error);
      }
    },
    [closeModal]
  );
  const methodReset = useCallback((e: any) => {
    e.preventDefault();
    reset();
    getData(search, page);
  }, []);
  return (
    <CommonLayout>
      <Container>
        <div className="payment-list-container">
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className="row_form"
            >
              <div className="grid">
                <FormDate label="from" name="startDate" />
                <FormDate label="to" name="endDate" />
              </div>
              {/* <FormCheck label="payment breakdown" name="payments" /> */}
              {/* <button type="submit" className="generate_button">
                Download As pdf
              </button>*/}
              <button onClick={methodReset}>Reset</button> 
            </form>
          </FormProvider>
          <table>
            <thead>
              <tr>
                <th>Invoice No</th>
                <th>date</th>
                <th>patient</th>
                <th>dentist </th>
                <th>total </th>
                <th>paid amount</th>
                <th>discount amount</th>
                <th>payment method</th>
                <th>due/advanced</th>
              </tr>
            </thead>
            <tbody>
              {payment.map((item: any, index) => (
                <React.Fragment key={index}>
                 
              
                    <tr  onClick={() => navigate(`/payment/invoice/${item?.treatmentId}`)}  >
                      <td>{item?.invoiceNo}</td>
                      <td>{item?.updatedAt?.split("T")[0]}</td>
                      <td>{item?.patientName}</td>
                      <td>{item?.doctorName}</td>
                      <td>{item?.payable}</td>
                      <td>{item?.cash + item?.card + item?.bankTransfer}</td>
                      <td>{item?.discount}</td>
                      <td>
                        {paymentMethod(
                          item?.card,
                          item?.cash,
                          item?.bankTransfer
                        )}
                      </td>
                      <td
                    
                      >
                        {item?.due}
                      </td>
                    </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>

          <BottomTab
            title="Patient"
            count={count}
            onPageChange={onPageChange}
          />
          {report && <AllPaymentReport closeModal={closeModal} />}
        </div>
      </Container>
    </CommonLayout>
  );
}

export default AllPaymentList;
function paymentMethod(card: number, cash: number, bank: number) {
  const methods = [];
  if (card > 0) methods.push("card");
  if (cash > 0) methods.push("cash");
  if (bank > 0) methods.push("bank");

  return methods.length > 0 ? methods.join("/") : "none";
}
