const initialState = {
  patients: [],
  patientsCount: 0,
};

export const patientsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_PATIENTS":
      return {
        ...state,
        patients: action.payload,
      };
    case "SET_PATIENTS_COUNT":
      return {
        ...state,
        patientsCount: action.payload,
      };
    case "SET_NEW_PATIENTS":
      if (state.patients.length > 19) {
        return {
          ...state,
          patients: [action.payload, ...state.patients].slice(0, -1),
          patientsCount: state.patientsCount + 1,
        };
      } else {
        return {
          ...state,
          patients: [action.payload, ...state.patients],
          patientsCount: state.patientsCount + 1,
        };
      }

    default:
      return state;
  }
};
