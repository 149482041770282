import CryptoJS from "crypto-js";

const decryptToken = (token: string, secret: string): string | undefined => {
    try {
      return CryptoJS?.AES.decrypt(token, secret).toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Error decrypting token:", error);
      return undefined;
    }
  };

const getDecryptedToken = (token: string | null): string | undefined => {
    if (!token) return undefined;
    
    const secret = process.env.REACT_APP_SECRET || "sfdsfasdfsdf3434$$$";
    const encryptedToken = localStorage.getItem(`${token}`);
    
    if (!encryptedToken) return undefined;

  return decryptToken(encryptedToken, secret);
};

export default getDecryptedToken;
