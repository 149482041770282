const initialState = {
  container: {
    top: 570,
    left: 604,
    show: true,
    size: 50,
    title:"container",
    name: "container"
  },
  logo: {
    top: 10,
    left: 10,
    show: true,
    size: 50,
    title:"logo",
    name: "clinic Logo"
  },
  header: {
    top: 20,
    left: 80,
    show: true,
    size: 20,
    title:"header",
    name: "Title"
  },
  invoiceNotext: {
    top: 20,
    left: 330,
    show: true,
    size: 13,
    title:"invoiceNotext",
    name: "Invoice Number Title"
  },
  invoiceNo: {
    top: 20,
    left: 430,
    show: true,
    size: 13,
    title:"invoiceNo",
    name: "Invoice Number"
  },
  datetimetext: {
    top: 40,
    left: 330,
    show: true,
    size: 13,
    title:"datetimetext",
    name: "Date Time Title"
  },
  datetime: {
    top: 40,
    left: 430,
    show: true,
    size: 13,
    title:"datetime",
    name: "Date Time"
  },
  regNotext: {
    top: 60,
    left: 330,
    show: true,
    size: 13,
    title:"regNotext",
    name: "Register Number Title"
  },
  regNo: {
    top: 60,
    left: 430,
    show: true,
    size: 13,
    title:"regNo",
    name: "Register Number"
  },
  patientNametext: {
    top: 96,
    left: 14,
    show: true,
    size: 13,
    title:"patientNametext",
    name: "Patient Name Title"
  },
  patientName: {
    top: 96,
    left: 140,
    show: true,
    size: 13,
    title:"patientName",
    name: "Patient Name"
  },
  agetext: {
    top: 80,
    left: 14,
    show: true,
    size: 13,
    title:"agetext",
    name: "age Title"
  },
  age: {
    top: 80,
    left: 140,
    show: true,
    size: 13,
    title:"age",
    name: "age Name"
  },
  contactNotext: {
    top: 96,
    left: 225,
    show: true,
    size: 13,
    title:"contactNotext",
    name: "Contact Number Title"
  },
  contactNo: {
    top: 96,
    left: 350,
    show: true,
    size: 13,
    title:"contactNo",
    name: "Contact Number"
  },
  gendertext: {
    top: 96,
    left: 465,
    show: true,
    size: 13,
    title:"gendertext",
    name: "Gender Title"
  },
  gender: {
    top: 96,
    left: 525,
    show: true,
    size: 13,
    title:"gender",
    name: "Gender"
  },
  horizontallineone: {
    top: 105,
    left: 10,
    show: true,
    size: 550,
    title:"horizontallineone",
    name: "Horizondal Line One"
  },
  horizontallinetwo: {
    top: 520,
    left: 10,
    show: true,
    size: 550,
    title:"horizontallinetwo",
    name: "Horizondal Line two"
  },
  treatmentDetail: {
    top: 125,
    left: 10,
    show: true,
    size: 550,
    title:"treatmentDetail",
    name: "Treatment Details"
  },
  billDetails: {
    top: 250,
    left: 10,
    show: true,
    size: 550,
    title:"billDetails",
    name: "Bill Details"
  },
  addresstext: {
    top: 540,
    left: 15,
    show: true,
    size: 13,
    title:"addresstext",
    name: "Address Title"
  },
  address: {
    top: 540,
    left: 75,
    show: true,
    size: 13,
    title:"address",
    name: "Address"
  },
  teltext: {
    top: 540,
    left: 450,
    show: true,
    size: 13,
    title:"teltext",
    name: "Mobile Number Title"
  },
  tel: {
    top: 540,
    left: 480,
    show: true,
    size: 13,
    title:"tel",
    name: "Mobile Number"
  },
  dentisttext: {
    top: 540,
    left: 250,
    show: true,
    size: 13,
    title:"dentisttext",
    name: "dentist Name Title"
  },
  dentist: {
    top: 540,
    left: 310,
    show: true,
    size: 13,
    title:"dentist",
    name: "dentist Name"
  },
};
type State = typeof initialState;

type ActionType = {
  type: string;
  payload: {
    name: keyof State;
    target: keyof State["logo"];
    value: number;
  };
};
export const printReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case "SET_PRINT_ACTIONS":
      const { name, target, value } = action.payload;
      return {
        ...state,
        [name]: {
          ...state[name],
          [target]: value,
        },
      };

    default:
      return state;
  }
};
