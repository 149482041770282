const initialState = {
  diagnoseTooth: [
    // {
    //   toothId: "",
    //   diagnose: "",
    //   toothNo: "",
    //   surface: "",
    //   note: "",
    //   priority: "",
    // },
  ],
  diagnoseNote: "",
  aditionalNote:""
};

export const diagnoseReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "ADD_DIAGNOSE":
    
      return {
        ...state,
        diagnoseTooth: [...state.diagnoseTooth, action.payload],
      };
    case "ADD_DIAGNOSE_NOTE":
      return {
        ...state,
        diagnoseNote: action.payload,
      };
    case "ADD_DIAGNOSES":
      return {
        ...state,
        diagnoseTooth: action.payload,
      };
    case "ADD_ADITIONAL_NOTE":
      return {
        ...state,
        aditionalNote: action.payload,
      };
    case "ADD_DIAGNOSES_IMAGE":
      return {
        ...state,
        diagnoseImage: action.payload,
      };
    case "ADD_TREATMENT_IMAGE":
      return {
        ...state,
        treatmentImage: action.payload,
      };
    case "REMOVE_DIAGNOSE":
      
      const updateddiagnoseTooth = state.diagnoseTooth.filter(
        (diagnose: any) => diagnose.toothId !== action.payload
      );
      return {
        ...state,
        diagnoseTooth: updateddiagnoseTooth,
      };
    default:
      return state;
  }
};
