const initialState = {
  patientData: "",
};
const someAction = (callback: Function) => ({
  type: "SEARCH_FUNCTION",
  payload: callback,
});
export const searchReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SEARCH_PATIENTS":
      const { searchPatient } = action.payload;
      return {
        ...state,
        patientData: searchPatient,
      };
      case 'SEARCH_FUNCTION':
        action.payload(); // Invoke the function
        return state;
    default:
      return state;
  }
};
