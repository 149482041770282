import React from "react";
import "./maincard.css";
import BottomTabButtons from "./BottomTabButtons";
function BottomTab({ title, count, onPageChange}:any) {
  return (
    <div className="bottomtab">
      <span>{count} {title ?? "menus"}</span>
      <BottomTabButtons rows={count} onPageChange={onPageChange} />
    </div>
  );
}

export default BottomTab;
