import React, { useState } from 'react';
import { ArrowLeftSVG, ArrowRightSVG } from "../../assets/icons";

function BottomTabButtons({ rows, onPageChange }:any) {
    
    const roundedNumber = Math.ceil(rows/20);
   
    const totalNumberOfPages = roundedNumber
    const maxVisibleButtons = 4;
    const [activePage, setActivePage] = useState(1);

    const handlePrevPage = () => {
        if (activePage > 1) {
            setActivePage(prevPage => prevPage - 1);
            onPageChange(activePage- 1);
        }
    };

    const handleNextPage = () => {
        if (activePage < totalNumberOfPages) {
            setActivePage(prevPage => prevPage + 1);
            onPageChange(activePage + 1);

        }
    };

    const handlePageClick = (page:any) => {
        setActivePage(page);
        if (onPageChange) {
            onPageChange(page);
        }
    };

    const getPageButtons = () => {
        const buttons = [];

        // Calculate the range of visible buttons centered around the active page
        let startPage = Math.max(1, activePage - Math.floor(maxVisibleButtons / 2));
        let endPage = Math.min(totalNumberOfPages, startPage + maxVisibleButtons - 1);

        // Adjust the range if it exceeds the total number of pages
        if (endPage - startPage + 1 < maxVisibleButtons) {
            startPage = Math.max(1, endPage - maxVisibleButtons + 1);
        }

        // Add ellipsis (...) if the range doesn't start from the first page
        if (startPage > 1) {
            buttons.push(
                <button key={1} onClick={() => handlePageClick(1)}>
                    1
                </button>
            );
            if (startPage > 2) {
                buttons.push(<button key="ellipsis-start" disabled>...</button>);
            }
        }

        // Add the visible page buttons
        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button key={i} className={activePage === i ? "btnActive" : ""} onClick={() => handlePageClick(i)}>
                    {i}
                </button>
            );
        }

        // Add ellipsis (...) if the range doesn't end at the last page
        if (endPage < totalNumberOfPages) {
            if (endPage < totalNumberOfPages - 1) {
                buttons.push(<button key="ellipsis-end" disabled>...</button>);
            }
            buttons.push(
                <button key={totalNumberOfPages} onClick={() => handlePageClick(totalNumberOfPages)}>
                    {totalNumberOfPages}
                </button>
            );
        }

        return buttons;
    };

    return (
        <div className="bottomtabbutton">
            <button onClick={handlePrevPage}>
                <ArrowLeftSVG />
            </button>
            {getPageButtons()}
            <button onClick={handleNextPage}>
                <ArrowRightSVG />
            </button>
        </div>
    );
}

export default BottomTabButtons;
