import React, { useMemo, Suspense, useCallback } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";
import CryptoJS from "crypto-js";
import { RootState } from "./redux/store";
import { ADMINROUTES, ALLROUTES } from "./routes";
import getDecryptedToken from "./api/getDecryptedToken";
import CustomLayout from "./layouts/customLayout/CustomLayout";
import LayoutMain from "./layouts/layoutMain/LayoutMain";

const Login = React.lazy(() => import("./views/login/Login"));
const Bill = React.lazy(() => import("./views/bill/Bill"));
const AdminLogIn = React.lazy(() => import("./views/adminLogin/AdminLogIn"));
const AdminLayout = React.lazy(
  () => import("./layouts/adminLayout/AdminLayout")
);
const MainLayout = React.lazy(() => import("./layouts/mainlayout/MainLayout"));
const Printtest = React.lazy(
  () => import("./client/pages/setting/printtest/Printtest")
);

const routePermission: string | null = localStorage.getItem("routePermission");
let permissionRoutes: number[] | undefined;

if (routePermission) {
  try {
    permissionRoutes = CryptoJS.AES.decrypt(
      routePermission,
      process.env.REACT_APP_SECRET || "sfdsfasdfsdf3434$$$"
    )
      .toString(CryptoJS.enc.Utf8)
      .split(",")
      .map(Number);
  } catch (error) {
    console.error("Error decrypting routePermission:", error);
  }
}

const decrypteduser: string | undefined = getDecryptedToken("user");

function App() {
  const loading = useSelector((state: RootState) => state.loader.active);

  const filterByIds = useCallback((array: any, ids: any) => {
    return array?.reduce((filtered: any, item: any) => {
      if (ids?.includes(item?.accessRoute?.KEY)) {
        const newItem = { ...item };

        if (item.subnav) {
          newItem.subnav = filterByIds(item?.subnav, ids);
        }

        filtered.push(newItem);
      }
      return filtered;
    }, []);
  }, []);

  const filteredArray = useMemo(
    () => filterByIds(ALLROUTES, permissionRoutes),
    [ALLROUTES, permissionRoutes, filterByIds]
  );

  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={
            <Circles
              height="80"
              width="80"
              color="#174d7a"
              ariaLabel="bars-loading"
              wrapperStyle={{
                position: "fixed",
                inset: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              wrapperClass=""
            />
          }
        >
          <Routes>
            <Route path="/print/:ID" element={<Printtest />} />
            <Route path="/invoice" element={<Bill />} />
            <Route path="/login" element={<Login />} />
            <Route path="/NewLayout" element={<CustomLayout />} />
            {/* <Route path="/forgot-password" element={<EnterEmail />} /> */}
            <Route path="/admin-login" element={<AdminLogIn />} />
            {decrypteduser === "admin" && (
              <Route path="/admin" element={<AdminLayout />}>
                <Route path="/admin" element={<Navigate to="dashboard" />} />
                {ADMINROUTES.map((item: any, i: number) =>
                  item?.topnav?.length > 0 ? (
                    <Route
                      key={i}
                      path={item?.path}
                      element={<item.Element subitem={item?.topnav} />}
                    >
                      <Route
                        path={item?.path}
                        element={<Navigate to={item?.topnav[0]?.path} />}
                      />
                      {item?.topnav?.map((subitem: any, j: number) => (
                        <Route
                          key={j}
                          path={subitem?.path}
                          element={<subitem.Element />}
                        />
                      ))}
                    </Route>
                  ) : (
                    <Route
                      key={i}
                      path={item?.path}
                      element={<item.Element subitem={item} />}
                    />
                  )
                )}
              </Route>
            )}
            {decrypteduser === "client" && (
              <Route
                path="/"
                element={<LayoutMain filteredArray={filteredArray} />}
              >
                <Route path="/" element={<Navigate to="dashboard" />} />
                {filteredArray.map((item: any, i: number) =>
                  item?.subnav?.length > 0 ? (
                    item?.subnav?.map((subitem: any, j: number) => (
                      <React.Fragment key={`${i}-${j}`}>
                        <Route
                          key={`${i}-${j}`}
                          path={subitem?.path}
                          element={<subitem.Element subitem={subitem} />}
                        >
                          {subitem?.subnav?.length > 0 && (
                            <Route
                              path={subitem?.path}
                              element={
                                <Navigate to={subitem?.subnav[0].path} />
                              }
                            />
                          )}
                          {subitem?.subnav?.length > 0 &&
                            subitem?.subnav?.map((subitems: any, k: number) => (
                              <Route
                                key={k}
                                path={subitems?.path}
                                element={<subitems.Element />}
                              />
                            ))}
                        </Route>
                      </React.Fragment>
                    ))
                  ) : (
                    <Route
                      key={i}
                      path={item?.path}
                      element={<item.Element subitem={item} />}
                    />
                  )
                )}
              </Route>
            )}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
