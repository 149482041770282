import { lazy } from "react";
import {
  AppointmentSVG,
  DashordSVG,
  PatientSVG,
  PaymentSVG,
  SettingSVG,
  StaffSVG,
  TreatmentSVG,
} from "./assets/icons";
import { ACCESSROUTES } from "./routes/accessRoutes";
import AllPaymentList from "./client/pages/payment/paymentList/AllPaymentList";

const AdminDashBoard =lazy(()=>import( "./admin/pages/adminDashboard/AdminDashBoard"));
const Clinic =lazy(()=>import( "./admin/pages/clinic/Clinic"));
const AddClinic =lazy(()=>import( "./admin/pages/clinic/addclinic/AddClinic"));
const AllClinic =lazy(()=>import( "./admin/pages/clinic/allclinic/AllClinic"));
const MakeAppointment =lazy(()=>import( "./client/pages/appointment/makeappointment/MakeAppointment"));
const ViewAppointMent =lazy(()=>import( "./client/pages/appointment/viewappointment/ViewAppointMent"));
const Dashboard =lazy(()=>import( "./client/pages/dashboard/Dashboard"));
const AddTreatment =lazy(()=>import( "./client/pages/manage/alltreatments/addtreatment/AddTreatment"));
const Diagnose =lazy(()=>import( "./client/pages/manage/alltreatments/addtreatment/diagnose/Diagnose"));
const PastTreatment =lazy(()=>import( "./client/pages/manage/alltreatments/addtreatment/pasttreatment/PastTreatment"));
const TreatmentTable =lazy(()=>import( "./client/pages/manage/alltreatments/addtreatment/treatmenttable/TreatmentTable"));
const TreatmentList =lazy(()=>import( "./client/pages/examinations/viewTreatments/TreatmentList"));
const ManageDiagnosis =lazy(()=>import( "./client/pages/manage/managediagnosis/ManageDiagnosis"));
const AddDiagnose =lazy(()=>import( "./client/pages/manage/managediagnosis/adddiagnose/AddDiagnose"));
const DiagnoseList =lazy(()=>import( "./client/pages/manage/managediagnosis/diagnoselist/DiagnoseList"));
const ManageTreatment =lazy(()=>import( "./client/pages/manage/managetreatment/ManageTreatment"));
const DeactiveTreatmentList =lazy(()=>import( "./client/pages/manage/managetreatment/deactiveTreatmentlist/DeactiveTreatmentList"));
const TreatmentAllList =lazy(()=>import( "./client/pages/manage/managetreatment/treatmentlist/TreatmentAllList"));
const AddPatient =lazy(()=>import( "./client/pages/patient/addpatient/AddPatient"));
const FullregisterPatient =lazy(()=>import( "./client/pages/patient/addpatient/fullregistration/FullregisterPatient"));
const QuickAddPatient =lazy(()=>import( "./client/pages/patient/addpatient/quickadd/QuickAddPatient"));
const EditPatient =lazy(()=>import( "./client/pages/patient/editpatient/EditPatient"));
const PatientOne =lazy(()=>import( "./client/pages/patient/patientone/PatientOne"));
const Appointments =lazy(()=>import( "./client/pages/patient/patientone/appointment/Appointments"));
const Examination =lazy(()=>import( "./client/pages/patient/patientone/examination/Examination"));
const General =lazy(()=>import( "./client/pages/patient/patientone/general/General"));
const Invoices =lazy(()=>import( "./client/pages/patient/patientone/invoices/Invoices"));
const Medical =lazy(()=>import( "./client/pages/patient/patientone/mediacal/Medical"));
const ViewPatient =lazy(()=>import( "./client/pages/patient/viewpatient/ViewPatient"));
const ActivePatient =lazy(()=>import( "./client/pages/patient/viewpatient/activepatient/ActivePatient"));
const DeactivePatient =lazy(()=>import( "./client/pages/patient/viewpatient/deactivepatient/DeactivePatient"));
const Invoice =lazy(()=>import( "./client/pages/payment/invoice/Invoice"));
const AllPayments =lazy(()=>import( "./client/pages/payment/payments/AllPayments"));
const Advanced =lazy(()=>import( "./client/pages/payment/payments/advanced/Advanced"));
const AllLastPayments =lazy(()=>import( "./client/pages/payment/payments/allpayments/AllLastPayments"));
const Completed =lazy(()=>import( "./client/pages/payment/payments/completed/Completed"));
const ShouldBePaid =lazy(()=>import( "./client/pages/payment/payments/shouldbepaid/ShouldBePaid"));
const ManagePrint =lazy(()=>import( "./client/pages/setting/manageprint/ManagePrint"));
const Managetime =lazy(()=>import( "./client/pages/setting/managetime/Managetime"));
const Employee =lazy(()=>import( "./client/pages/staff/employee/Employee"));
const AddEmployee =lazy(()=>import( "./client/pages/staff/employee/addemployee/AddEmployee"));
const DeactiveEmployee =lazy(()=>import( "./client/pages/staff/employee/deactiveEmployee/DeactiveEmployee"));
const Employees =lazy(()=>import( "./client/pages/staff/employee/employees/Employees"));
const Role =lazy(()=>import( "./client/pages/staff/role/Role"));
const AddRole =lazy(()=>import( "./client/pages/staff/role/addRole/AddRole"));
const DeactiveRole =lazy(()=>import( "./client/pages/staff/role/deactiveroles/DeactiveRole"));
const Roles =lazy(()=>import( "./client/pages/staff/role/roles/Roles"));
const ViewRole =lazy(()=>import( "./client/pages/staff/role/viewrole/ViewRole"));
const EditClinic =lazy(()=>import( "./admin/pages/clinic/editClinic/EditClinic"));
const Payment =lazy(()=>import( "./admin/pages/payment/Payment"));
const AllPayment =lazy(()=>import( "./admin/pages/payment/allPayment/AllPayment"));
const ViewClinic =lazy(()=>import( "./admin/pages/clinic/viewClinic/ViewClinic"));
const PendingPayment =lazy(()=>import( "./admin/pages/payment/pendingPayment/PendingPayment"));
const CompletePayment =lazy(()=>import( "./admin/pages/payment/completePayment/CompletePayment"));
const AdvancedPayment =lazy(()=>import( "./admin/pages/payment/advancedPayment/AdvancedPayment"));
const Category =lazy(()=>import( "./client/pages/inventory/category/Category"));
const AllCategory =lazy(()=>import( "./client/pages/inventory/category/allCategory/AllCategory"));
const Product =lazy(()=>import( "./client/pages/inventory/product/Product"));
const AllProduct =lazy(()=>import( "./client/pages/inventory/product/allProduct/AllProduct"));
const Brand =lazy(()=>import( "./client/pages/inventory/brand/Brand"));
const AllBrand =lazy(()=>import( "./client/pages/inventory/brand/allBrand/AllBrand"));
const Supplier =lazy(()=>import( "./client/pages/inventory/supplier/Supplier"));
const AllSupplier =lazy(()=>import( "./client/pages/inventory/supplier/allSupplier/AllSupplier"));
const Purchase =lazy(()=>import( "./client/pages/inventory/purchase/Purchase"));
const AllPurchase =lazy(()=>import( "./client/pages/inventory/purchase/allPurchase/AllPurchase"));
const AddPurchase =lazy(()=>import( "./client/pages/inventory/purchase/addPurchase/AddPurchase"));
const ProductRelease =lazy(()=>import( "./client/pages/inventory/productRelease/ProductRelease"));
const AllRelease =lazy(()=>import( "./client/pages/inventory/productRelease/allRelease/AllRelease"));
const AddRelease =lazy(()=>import( "./client/pages/inventory/productRelease/addRelease/AddRelease"));
const AllAppointment =lazy(()=>import( "./client/pages/appointment/viewappointment/allAppointment/AllAppointment"));
const YourAppointment =lazy(()=>import( "./client/pages/appointment/viewappointment/yourAppointment/YourAppointment"));
const MyAppointment =lazy(()=>import( "./client/pages/myaccount/myappointments/MyAppointment"));
const ManagePurpose =lazy(()=>import( "./client/pages/setting/managepurpose/ManagePurpose"));

// if isParam is true sub nav will not see on sub nav
// top navbar is is inside component top bar
// subnave bar means side subnav item
export const ALLROUTES = [
  {
    title: "Dashboard",
    icon: DashordSVG,
    Element: Dashboard,
    accessRoute: ACCESSROUTES.DASHBOARD,
    path: ACCESSROUTES.DASHBOARD.PATH,
  },
  {
    title: "Patients",
    icon: PatientSVG,
    accessRoute: ACCESSROUTES.PATIENTS,
    path: ACCESSROUTES.PATIENTS.PATH,
    subnav: [
      {
        title: "Add Patient",
        icon: DashordSVG,
        Element: AddPatient,
        accessRoute: ACCESSROUTES.ADDPATIENTS,
        path: ACCESSROUTES.ADDPATIENTS.PATH,
        subnav: [
          {
            title: "QUICK ADD",
            icon: DashordSVG,
            Element: QuickAddPatient,
            accessRoute: ACCESSROUTES.ADDPATIENTSQUICKADD,
            path: ACCESSROUTES.ADDPATIENTSQUICKADD.PATH,
          },
          {
            title: "FULL REGISTRATION",
            icon: DashordSVG,
            Element: FullregisterPatient,
            accessRoute: ACCESSROUTES.ADDPATIENTSFULLREGISTRATION,
            path: ACCESSROUTES.ADDPATIENTSFULLREGISTRATION.PATH,
          },
        ],
      },
      {
        title: "Patients List",
        icon: DashordSVG,
        Element: ViewPatient,
        accessRoute: ACCESSROUTES.VIEWPATIENTS,
        path: ACCESSROUTES.VIEWPATIENTS.PATH,
        subnav: [
          {
            title: "ACTIVE",
            Element: ActivePatient,
            accessRoute: ACCESSROUTES.VIEWPATIENTSACIVE,
            path: ACCESSROUTES.VIEWPATIENTSACIVE.PATH,
          },
          {
            title: "DEACTIVE",
            Element: DeactivePatient,
            accessRoute: ACCESSROUTES.VIEWPATIENTSDEACIVE,
            path: ACCESSROUTES.VIEWPATIENTSDEACIVE.PATH,
          },
        ],
      },
      {
        title: "edit Patients",
        icon: DashordSVG,
        Element: EditPatient,
        hide: true,
        accessRoute: ACCESSROUTES.EDITPATIENTS,
        path: ACCESSROUTES.EDITPATIENTS.PATH,
      },
      {
        title: "test",
        icon: DashordSVG,
        Element: PatientOne,
        hide: true,
        accessRoute: ACCESSROUTES.PATIENTSDETAIL,
        path: ACCESSROUTES.PATIENTSDETAIL.PATH,
        subnav: [
          {
            title: "ACTIVE",
            Element: General,
            accessRoute: ACCESSROUTES.PATIENTSGENERALDETAIL,
            path: ACCESSROUTES.PATIENTSGENERALDETAIL.PATH,
          },
          {
            title: "DEACTIVE",
            Element: Examination,
            accessRoute: ACCESSROUTES.PATIENTSEXAMINATIONDETAIL,
            path: ACCESSROUTES.PATIENTSEXAMINATIONDETAIL.PATH,
          },
          {
            title: "DEACTIVE",
            Element: Medical,
            accessRoute: ACCESSROUTES.PATIENTSMEDICALHISTORYDETAIL,
            path: ACCESSROUTES.PATIENTSMEDICALHISTORYDETAIL.PATH,
          },
          {
            title: "DEACTIVE",
            Element: Appointments,
            accessRoute: ACCESSROUTES.PATIENTSAPPOINTMENTSDETAIL,
            path: ACCESSROUTES.PATIENTSAPPOINTMENTSDETAIL.PATH,
          },
          {
            title: "DEACTIVE",
            Element: Invoices,
            accessRoute: ACCESSROUTES.PATIENTSINVOCEDETAIL,
            path: ACCESSROUTES.PATIENTSINVOCEDETAIL.PATH,
          },
        ],
      },
    ],
  },
  {
    title: "Treatments",
    icon: TreatmentSVG,
    accessRoute: ACCESSROUTES.EXAMINATIONANDTREATMENTS,
    path: ACCESSROUTES.EXAMINATIONANDTREATMENTS.PATH,
    subnav: [
    

      {
        title: "View Treatments",
        icon: DashordSVG,
        accessRoute: ACCESSROUTES.VIEWTREATMENTS,
        path: ACCESSROUTES.VIEWTREATMENTS.PATH,
        Element: TreatmentList,
      },
      {
        title: "Treatment",
        icon: DashordSVG,
        accessRoute: ACCESSROUTES.ADDTREATMENTDIAGNOSIS,
        path: ACCESSROUTES.ADDTREATMENTDIAGNOSIS.PATH,
        hide: true,
        Element: AddTreatment,
        subnav: [
          {
            title: "ACTIVE",
            accessRoute: ACCESSROUTES.ADDTREATMENTDIAGNOSISADDDIAGNOSE,
            path: ACCESSROUTES.ADDTREATMENTDIAGNOSISADDDIAGNOSE.PATH,
            Element: Diagnose,
          },
          {
            title: "DEACTIVE",
            accessRoute: ACCESSROUTES.ADDTREATMENTDIAGNOSISTREATMENTTABLE,
            path: ACCESSROUTES.ADDTREATMENTDIAGNOSISTREATMENTTABLE.PATH,
            Element: TreatmentTable,
          },
          {
            title: "DEACTIVE",
            accessRoute: ACCESSROUTES.ADDTREATMENTDIAGNOSISPASTTREATMENT,
            path: ACCESSROUTES.ADDTREATMENTDIAGNOSISPASTTREATMENT.PATH,
            Element: PastTreatment,
          },
        ],
      },
    ],
  },
  {
    title: "Payments",
    icon: PaymentSVG,
    accessRoute: ACCESSROUTES.PAYMENTS,
    path: ACCESSROUTES.PAYMENTS.PATH,
    subnav: [
      {
        title: "Create Invoice",
        icon: DashordSVG,
        accessRoute: ACCESSROUTES.CREATINVOICE,
        path: ACCESSROUTES.CREATINVOICE.PATH,
        Element: Invoice,
      },
      {
        title: "Invoice",
        icon: DashordSVG,
        accessRoute: ACCESSROUTES.GOTOPAY,
        path: ACCESSROUTES.GOTOPAY.PATH,
        Element: Invoice,
        hide: true,
      },
     
      {
        title: "invoices list",
        icon: DashordSVG,
        accessRoute: ACCESSROUTES.PAYMENTLIST,
        path: ACCESSROUTES.PAYMENTLIST.PATH,
        Element: AllPayments,
        subnav: [
          {
            title: "ALL invoices",
            accessRoute: ACCESSROUTES.ALLLASTPAYMENT,
            path: ACCESSROUTES.ALLLASTPAYMENT.PATH,
            Element: AllLastPayments,
          },
          {
            title: "DUE invoices",
            accessRoute: ACCESSROUTES.SHOULDBEPAY,
            path: ACCESSROUTES.SHOULDBEPAY.PATH,
            Element: ShouldBePaid,
          },
          {
            title: "ADVANCE",
            accessRoute: ACCESSROUTES.ADVANCED,
            path: ACCESSROUTES.ADVANCED.PATH,
            Element: Advanced,
          },
          {
            title: "COMPLETED",
            accessRoute: ACCESSROUTES.COMPLETEDPAYMENT,
            path: ACCESSROUTES.COMPLETEDPAYMENT.PATH,
            Element: Completed,
          },
        ],
      },
      {
        title: "payment list",
        icon: DashordSVG,
        accessRoute: ACCESSROUTES.ALLPAYMENTLIST,
        path: ACCESSROUTES.ALLPAYMENTLIST.PATH,
        Element: AllPaymentList,
      },
    ],
  },
  {
    title: "Staff",
    icon: StaffSVG,
    accessRoute: ACCESSROUTES.STAFF,
    path: ACCESSROUTES.STAFF.PATH,
    subnav: [
      {
        title: "Role",
        icon: DashordSVG,
        accessRoute: ACCESSROUTES.ROLE,
        path: ACCESSROUTES.ROLE.PATH,
        Element: Role,
        subnav: [
          {
            title: "ACTIVE",
            accessRoute: ACCESSROUTES.ALLROLE,
            path: ACCESSROUTES.ALLROLE.PATH,
            Element: Roles,
          },
          {
            title: "Deactive",
            accessRoute: ACCESSROUTES.DEACTIVEROLE,
            path: ACCESSROUTES.DEACTIVEROLE.PATH,
            Element: DeactiveRole,
          },
       
          {
            title: "Add Role",
            accessRoute: ACCESSROUTES.ADDROLE,
            path: ACCESSROUTES.ADDROLE.PATH,
            Element: AddRole,
          },
          {
            title: "EDITROLE",
            accessRoute: ACCESSROUTES.EDITROLE,
            path: ACCESSROUTES.EDITROLE.PATH,
            Element: AddRole,
          },
        ],
      },
      {
        title: "employee",
        icon: DashordSVG,
        accessRoute: ACCESSROUTES.EMPLOYEE,
        path: ACCESSROUTES.EMPLOYEE.PATH,
        Element: Employee,
        subnav: [
          {
            title: " ALL EMPLOYEES",
            accessRoute: ACCESSROUTES.ALLEMPLOYEES,
            path: ACCESSROUTES.ALLEMPLOYEES.PATH,
            Element: Employees,
          },
          {
            title: "add EMPLOYEE",
            accessRoute: ACCESSROUTES.ADDEMPLOYEE,
            path: ACCESSROUTES.ADDEMPLOYEE.PATH,
            Element: AddEmployee,
          },
          {
            title: "DEACtIVE EMPLOYEE",
            accessRoute: ACCESSROUTES.DEACTIVEEMPLOYEES,
            path: ACCESSROUTES.DEACTIVEEMPLOYEES.PATH,
            Element: DeactiveEmployee,
          },
          {
            title: "edit",
            accessRoute: ACCESSROUTES.EDITEMPLOYEE,
            path: ACCESSROUTES.EDITEMPLOYEE.PATH,
            Element: AddEmployee,
          },
        ],
      },
    ],
  },

  {
    title: "Appointments",
    icon: AppointmentSVG,
    accessRoute: ACCESSROUTES.APPOINTMENT,
    path: ACCESSROUTES.APPOINTMENT.PATH,
    subnav: [
      {
        title: "Make An Appointment",
        accessRoute: ACCESSROUTES.MAKEAPPOINTMENT,
        path: ACCESSROUTES.MAKEAPPOINTMENT.PATH,
        Element: MakeAppointment,
      },
      {
        title: "Appointments",
        accessRoute: ACCESSROUTES.VIEWAPPOINTMENT,
        path: ACCESSROUTES.VIEWAPPOINTMENT.PATH,
        Element: ViewAppointMent,
        subnav: [
          {
            title: "MY appointment",
            accessRoute: ACCESSROUTES.MYAPPOINTMENT,
            path: ACCESSROUTES.MYAPPOINTMENT.PATH,
            Element: YourAppointment,
          },
          {
            title: "all appointment",
            accessRoute: ACCESSROUTES.ALLAPPOINTMENT,
            path: ACCESSROUTES.ALLAPPOINTMENT.PATH,
            Element: AllAppointment,
          },
        ],
      },
    ],
  },
  {
    title: "Clinical settings",
    icon: SettingSVG,
    accessRoute: ACCESSROUTES.SETTING,
    path: ACCESSROUTES.SETTING.PATH,
    subnav: [
      {
        title: "manage time",
        accessRoute: ACCESSROUTES.SETTINGMANGETIME,
        path: ACCESSROUTES.SETTINGMANGETIME.PATH,
        Element: Managetime,
      },
      {
        title: "manage print",
        accessRoute: ACCESSROUTES.SETTINGMANGEPRINT,
        path: ACCESSROUTES.SETTINGMANGEPRINT.PATH,
        Element: ManagePrint,
      },
      {
        title: "manage purpose",
        accessRoute: ACCESSROUTES.SETTINGMANGEPURPOSE,
        path: ACCESSROUTES.SETTINGMANGEPURPOSE.PATH,
        Element: ManagePurpose,
      },
      {
        title: "Treatments & Price List",
        icon: DashordSVG,
        accessRoute: ACCESSROUTES.TREATMENTANDPRICELIST,
        path: ACCESSROUTES.TREATMENTANDPRICELIST.PATH,
        Element: ManageTreatment,
        subnav: [
          {
            title: "All Treatment",
            accessRoute: ACCESSROUTES.TREATMENTLIST,
            path: ACCESSROUTES.TREATMENTLIST.PATH,
            Element: TreatmentAllList,
          },
       
          {
            title: "DEACTIVE Treatment",
            accessRoute: ACCESSROUTES.DEACTIVETREATMENTLIST,
            path: ACCESSROUTES.DEACTIVETREATMENTLIST.PATH,
            Element: DeactiveTreatmentList,
          },
        ],
      },
      {
        title: "Diagnosis List",
        icon: DashordSVG,
        accessRoute: ACCESSROUTES.DIAGNOSIS,
        path: ACCESSROUTES.DIAGNOSIS.PATH,
        Element: ManageDiagnosis,
        subnav: [
          {
            title: "Active",
            accessRoute: ACCESSROUTES.DIAGNOSELIST,
            path: ACCESSROUTES.DIAGNOSELIST.PATH,
            Element: DiagnoseList,
          },
          {
            title: "Deactive",
            accessRoute: ACCESSROUTES.ADDDIAGNOSIS,
            path: ACCESSROUTES.ADDDIAGNOSIS.PATH,
            Element: AddDiagnose,
          },
        ],
      },
    ],
  },
  {
    title: "my account",
    icon: SettingSVG,
    accessRoute: ACCESSROUTES.MYACCOUNT,
    path: ACCESSROUTES.MYACCOUNT.PATH,
    subnav: [
      {
        title: "my appointment",
        accessRoute: ACCESSROUTES.MYAPPOINTMENTS,
        path: ACCESSROUTES.MYAPPOINTMENTS.PATH,
        Element: MyAppointment,
      },
    
    ],
  },

  {
    title: "Inventory",
    icon: AppointmentSVG,
    accessRoute: ACCESSROUTES.INVENTORY,
    path: ACCESSROUTES.INVENTORY.PATH,
    subnav: [

      {
        title: "Category",
        accessRoute: ACCESSROUTES.CATEGORY,
        path: ACCESSROUTES.CATEGORY.PATH,
        Element: Category,
        subnav: [
          {
            title: "all Category",
            accessRoute: ACCESSROUTES.ALLCATEGORY,
            path: ACCESSROUTES.ALLCATEGORY.PATH,
            Element: AllCategory,
          },
        ],
      },
      {
        title: "Product",
        accessRoute: ACCESSROUTES.PRODUCT,
        path: ACCESSROUTES.PRODUCT.PATH,
        Element: Product,
        subnav: [
          {
            title: "all Product",
            accessRoute: ACCESSROUTES.ALLPRODUCT,
            path: ACCESSROUTES.ALLPRODUCT.PATH,
            Element: AllProduct,
          },
        ],
      },
      {
        title: "Brand",
        accessRoute: ACCESSROUTES.BRAND,
        path: ACCESSROUTES.BRAND.PATH,
        Element: Brand,
        subnav: [
          {
            title: "all Brand",
            accessRoute: ACCESSROUTES.ALLBRAND,
            path: ACCESSROUTES.ALLBRAND.PATH,
            Element: AllBrand,
          },
        ],
      },
      {
        title: "Supplier",
        accessRoute: ACCESSROUTES.SUPPLIER,
        path: ACCESSROUTES.SUPPLIER.PATH,
        Element: Supplier,
        subnav: [
          {
            title: "all Supplier",
            accessRoute: ACCESSROUTES.ALLSUPPLIER,
            path: ACCESSROUTES.ALLSUPPLIER.PATH,
            Element: AllSupplier,
          },
        ],
      },
      {
        title: "Purchase",
        accessRoute: ACCESSROUTES.PURCHASE,
        path: ACCESSROUTES.PURCHASE.PATH,
        Element: Purchase,
        subnav: [
          {
            title: "all Purchase",
            accessRoute: ACCESSROUTES.ALLPURCHASE,
            path: ACCESSROUTES.ALLPURCHASE.PATH,
            Element: AllPurchase,
          },
          {
            title: "add Purchase",
            accessRoute: ACCESSROUTES.ADDPURCHASE,
            path: ACCESSROUTES.ADDPURCHASE.PATH,
            Element: AddPurchase,
          },
          {
            title: "edit Purchase",
            accessRoute: ACCESSROUTES.EDITPURCHASE,
            path: ACCESSROUTES.EDITPURCHASE.PATH,
            Element: AddPurchase,
          },
        ],
      },
      {
        title: "release",
        accessRoute: ACCESSROUTES.PRODUCTRELEASE,
        path: ACCESSROUTES.PRODUCTRELEASE.PATH,
        Element: ProductRelease,
        subnav: [
          {
            title: "all release",
            accessRoute: ACCESSROUTES.ALLPRODUCTRELEASE,
            path: ACCESSROUTES.ALLPRODUCTRELEASE.PATH,
            Element: AllRelease,
          },
          {
            title: "Add release",
            accessRoute: ACCESSROUTES.ADDPRODUCTRELEASE,
            path: ACCESSROUTES.ADDPRODUCTRELEASE.PATH,
            Element: AddRelease,
          },
        ],
      },
    ],
  },
];
export const ADMINROUTES = [
  {
    title: "Dashboard",
    icon: DashordSVG,
    Element: AdminDashBoard,
    path: "/admin/dashboard",
  },
  {
    title: "Clincs",
    icon: DashordSVG,
    Element: Clinic,
    path: "/admin/clincs",
    topnav: [
      {
        title: "All Clinics",
        path: "/admin/clincs/all-clinic",
        Element: AllClinic,
      },
      {
        title: "Add Clinics",
        path: "/admin/clincs/add-clinic",
        Element: AddClinic,
      },
      {
        title: "Edit Clinics",
        path: "/admin/clincs/edit-clinic/:ID",
        Element: EditClinic,
        isParam: true,
      },
      {
        title: "Edit Clinics",
        path: "/admin/clincs/view-clinic/:ID",
        Element: ViewClinic,
        isParam: true,
      },
    ],
  },
  {
    title: "Payment",
    icon: DashordSVG,
    path: "/admin/payment",
    Element:Payment,
    topnav: [
      {
        title: "all payment",
        path: "/admin/payment/all-payment",
        Element: AllPayment,
      },
      {
        title: "complete payment",
        path: "/admin/payment/complete-payment",
        Element: CompletePayment,
      },
      {
        title: "pending payment",
        path: "/admin/payment/pending-payment",
        Element: PendingPayment,
      },
      {
        title: "advanced payment",
        path: "/admin/payment/advanced-payment",
        Element: AdvancedPayment,
      },
    ],
  },
  {
    title: "Setting",
    icon: DashordSVG,
    Element: Payment,
    path: "/admin/setting",
    topnav: [
      {
        title: "Register Number",
        path: "/admin/setting/regnumber",
        Element: AllClinic,
      },
    ],
  },
];
