

import React from "react";
import { Controller, useFormContext } from "react-hook-form";
function FormCheck({
  name,
  type,
  label,
  errors,
  errormessage,
  placeholder,
  defaultValue,
  ...others
}: any) {
  const { control } = useFormContext();
  return (
    <div className="form__group__check">

      <Controller
        name={name ? name : ""}
        control={control}
        defaultValue={defaultValue ? defaultValue : ""}
        render={({ field: { onChange, value } }) => (
          <input
            onChange={onChange}
            checked={value}
            type={"checkbox"}
            className="form__field "
            placeholder={placeholder ? placeholder : ""}
            {...others} 
          />
        )}
      />
      <label className="form__label">{label ? label : "name"}</label>

      {errors && (
        <label className="form__label__error">
          {errormessage ? errormessage : "error"}
        </label>
      )}
    </div>
  );
}

export default FormCheck;
