import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import CryptoJS from "crypto-js";
import { ACCESSACTION } from "../routes/accessActions";

// Function to decrypt a token
const decryptToken = (token: string, secret: string): string | undefined => {
  try {
    return CryptoJS.AES.decrypt(token, secret).toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Error decrypting token:", error);
    return undefined;
  }
};

// Retrieve the token and accessPermission from localStorage
const token: string | null = localStorage.getItem("token");
const accessPermission: string | null = localStorage.getItem("accessPermission");

// Decrypt the token and accessPermission if they exist
const secret = process.env.REACT_APP_SECRET || "sfdsfasdfsdf3434$$$";
const decryptedToken = token ? decryptToken(token, secret) : undefined;
const decryptedAccessPermission = accessPermission ? decryptToken(accessPermission, secret) : undefined;

// Parse the decrypted accessPermission into an array of numbers
let accessActions: number[] | undefined;
if (decryptedAccessPermission) {
  try {
    accessActions = decryptedAccessPermission.split(",").map(Number);
  } catch (error) {
    console.error("Error parsing accessPermission:", error);
  }
}

// Create Axios instance with base URL and headers
const baseUrl: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    Authorization: `Bearer ${decryptedToken || ""}`,
    'Content-Type': 'application/json',
  },
});

// Request interceptor to check access permissions
baseUrl.interceptors.request.use((config) => {
  for (const key of Object.keys(ACCESSACTION)) {
    const action = ACCESSACTION[key];
    if (accessActions?.includes(action?.KEY) && config.url?.includes(action.PATH)) {
      const customError: AxiosError = new Error("Blocked") as AxiosError;
      customError.response = {
        status: 403,
        statusText: "Unauthorized",
        data: "Blocked",
        headers: {},
        config: config,
      };
      customError.config = config;
      customError.isAxiosError = true;
      customError.toJSON = () => ({
        message: "Blocked",
        name: "AxiosError",
        config: config,
        response: {
          status: 403,
          statusText: "Unauthorized",
          headers: {},
          data: "Blocked",
        },
      });
      throw customError;
    }
  }
  return config;
});

// Response interceptor to handle unauthorized responses
baseUrl.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default baseUrl;
