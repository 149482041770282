import React, { ReactNode } from "react";
import "./container.scss";
function Container({
  children,
  className,
}: {
  children: ReactNode;
  className?: string | null;
}) {
  return <div className={`commoncontainer ${className ?? ""}`}>{children}</div>;
}

export default Container;
